import { useState } from 'react';
import { SurveyCreator } from 'survey-creator-react';

import { useQueryParams } from '@components/router';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import CourseAssetsModal from '../CourseAssetsModal';
import CoursesDetailsForm from './CoursesDetailsForm';
import RetailerCoursesDetailsForm from './RetailerCoursesDetailsForm';
import SurveyCreatorWrapper from './SurveyCreator/SurveyCreator';

const AdminCoursesForm = ({ creator }: { creator: SurveyCreator }) => {
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const { enableGallery } = useQueryParams();
    const { account } = useSparkplugAccount();

    return (
        <>
            <div className="flex w-full ">
                <div className="w-1/4 bg-white max-w-[310px] border-r border-gray-200 border-solid">
                    {account?.type === 'retailer' ? (
                        <RetailerCoursesDetailsForm />
                    ) : (
                        <CoursesDetailsForm />
                    )}
                </div>
                <div className="flex-1">
                    <SurveyCreatorWrapper surveyCreator={creator} />

                    <CourseAssetsModal
                        isVisible={showGalleryModal && !!enableGallery}
                        onCloseHandler={() => setShowGalleryModal(false)}
                    />
                </div>
            </div>
        </>
    );
};
export default AdminCoursesForm;
