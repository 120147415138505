import { formatCurrency } from '@sparkplug/lib';

import Button from '@components/buttons/Button';

import { Badge } from '@app/componentLibrary/Badge';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography';

type CourseCardProps = {
    resource: {
        learningResourceId: string;
        brandPhoto?: string | null;
        accountPhoto?: string | null;
        brandName?: string | null;
        accountName?: string | null;
        reward?: number | null;
        name: string;
        description: string;
        budget?: number | null;
        surveyResponse?: { status?: string };
    };
    isCompleted?: boolean;
    onCardClick: (resourceId: string) => void;
};

export const CourseCard = ({ resource, isCompleted, onCardClick }: CourseCardProps) => {
    const getButtonText = () => {
        if (isCompleted) return 'View completed course';
        if (resource.surveyResponse)
            return `Continue Course ${resource.budget ? '(Limited Rewards)' : ''}`;
        if (resource.budget && resource.budget > 0 && resource.reward) {
            return `Earn ${formatCurrency(resource.reward / 100)} (Limited Rewards)`;
        }
        return 'Start Course';
    };

    return (
        <Card className="mb-4">
            <CardHeader className="mb-3">
                <CardTitle className="flex flex-row items-center justify-between">
                    <img
                        src={resource.brandPhoto ?? resource.accountPhoto ?? ''}
                        alt={resource.brandName ?? resource.accountName ?? ''}
                        className="w-10 h-10 rounded-full mr-2"
                    />
                    <div className="flex flex-col space-y-1 items-end">
                        <Badge
                            color="blue"
                            label={resource.brandName ?? resource.accountName}
                            className="w-fit"
                        />
                        <Badge
                            color={isCompleted ? 'dark-gray' : 'green'}
                            className="w-fit"
                            label={`${isCompleted ? 'Earned ' : '+ '}${
                                resource.reward ? formatCurrency(resource.reward / 100) : 0
                            }`}
                        />
                    </div>
                </CardTitle>
            </CardHeader>
            <CardContent>
                <Typography variant="sm" className="font-semibold uppercase mb-1">
                    Course
                </Typography>
                <Typography variant="base" className="text-gray-950 font-bold">
                    {resource.name}
                </Typography>
                <Typography variant="base" className="text-gray-900">
                    {resource.description}
                </Typography>
            </CardContent>
            <CardFooter>
                <Button
                    variant="outlined"
                    color="blue"
                    className="w-full"
                    onClick={() => onCardClick(resource.learningResourceId)}
                >
                    {getButtonText()}
                </Button>
            </CardFooter>
        </Card>
    );
};
