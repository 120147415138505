import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { VersionService } from './VersionService';

const VersionEnforcer = () => {
    const location = useLocation();

    useEffect(() => {
        const pathname = location?.pathname;

        const bypassPaths = [
            '/sparks/create',
            '/courses/create',
            '/control-center/courses/create',
            // edit paths are preceeded by an id.
            // This is a simple way to target any edit paths. It will be more broad than sparks and courses create paths above.
            '/edit',
        ];

        const shouldBypass = bypassPaths.some((path) => pathname && pathname.includes(path));
        // when creating a spark, all the data entered is stored in context UI-side
        // if we enforce a reload while an admin is in this state, all their progress is lost
        // similarly, when editing a spark, any new data could be lost
        // if/when we tackle https://linear.app/sparkplug/issue/X-1513/find-a-better-way-to-store-spark-data-during-create-process we can remove this workaround
        if (!shouldBypass) {
            VersionService.enforce();
        }
    }, [location]);

    return null;
};

export default VersionEnforcer;
