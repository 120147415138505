import { FC } from 'react';
import { Link } from 'react-router-dom';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import clsx from 'clsx';
import moment from 'moment';

import { Credit, LedgerEntry } from '@sparkplug/lib';

import { useGroupSparkCreditEntries } from '@features/spark-credits/queries';
import { isCredit, isDebit } from '@features/spark-credits/utils';

import IconButton from '@components/buttons/IconButton';
import { NoSparksGraphic } from '@components/graphics';
import { ArrowBackIos as ArrowBackIosIcon, Download } from '@components/icons';
import Spinner from '@components/layout/Spinner';

import { currencyFormatterFactory } from '@helpers/chartFormatters';
import { getDomainFromEnv } from '@helpers/getDomainFromEnv';

interface SparkCreditHistoryProps {
    basePath: string;
    accountId?: string;
}
const generateCsvContent = (creditHistory: LedgerEntry[], accountId: string) => {
    const headers = ['Date Created', 'Date Withdrawn', 'Amount', 'Link to Spark'];
    const rows = creditHistory.map((entry) => {
        const { createdAt, amount } = entry;
        const sparkId = isDebit(entry) ? entry.sparkId : '';
        const withdrawnAt = isDebit(entry) ? entry.withdrawnAt : '';
        return [
            moment(createdAt).format('MM/DD/YYYY'),
            moment(withdrawnAt).format('MM/DD/YYYY'),
            amount,
            sparkId ? `${getDomainFromEnv()}/${accountId}/sparks/${sparkId}` : '',
        ];
    });

    const csvContent = [headers, ...rows].map((row) => row.join(',')).join('\n');
    return csvContent;
};
const SparkCreditHistoryItem: FC<{ data: Credit & { balance: number }; accountId: string }> = ({
    data,
    accountId,
}) => {
    const formatter = currencyFormatterFactory(2);
    const { amount, creditType, createdAt, balance } = data;
    const sparkId = isDebit(data) ? data.sparkId : '';
    const withdrawnAt = isDebit(data) ? data.withdrawnAt : '';

    return (
        <div className="credit-item pt-4">
            <div className="flex justify-between items-center mb-2">
                <div
                    className={clsx('amount', {
                        'text-aqua-700': isCredit(data),
                        'text-sienna-600': isDebit(data),
                    })}
                >
                    {formatter(amount)}
                </div>
                <div className="text-xs text-gray-500">{formatter(balance)}</div>
            </div>
            <div className="date">
                {isCredit(data) && <div className="type capitalize pb-1">{creditType} credit</div>}
                {isDebit(data) && (
                    <div>
                        Withdrawn on{' '}
                        {withdrawnAt ? moment(withdrawnAt).format(DATE_DISPLAY_FORMAT) : 'unknown'}
                        {sparkId && (
                            <>
                                {' '}
                                <a
                                    href={`/${accountId}/sparks/${sparkId}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    for this spark.
                                </a>
                            </>
                        )}
                    </div>
                )}
                {isCredit(data) && (
                    <div>
                        Earned on{' '}
                        {createdAt ? moment(createdAt).format(DATE_DISPLAY_FORMAT) : 'unknown'}
                    </div>
                )}
            </div>
        </div>
    );
};

const NoCreditHistory: FC = () => {
    return (
        <div className="no-credit-history">
            <NoSparksGraphic />
            <div>No Credits Found</div>
        </div>
    );
};

const SparkCreditHistory: FC<SparkCreditHistoryProps> = ({ basePath, accountId }) => {
    if (!accountId) {
        return <NoCreditHistory />;
    }
    const {
        groupSparkCreditEntries: groupCreditSparkCredit = [],
        groupSparkCreditEntriesAreReady: groupSparkCreditIsReady,
    } = useGroupSparkCreditEntries({
        groupId: accountId,
        creditsOnly: false,
    });
    const downloadCsv = (csvContent: string, filename: string) => {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    let runningBalance = 0;

    // Sort transactions by createdAt date
    const sortedTransactions = [...groupCreditSparkCredit].sort(
        (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );

    const transactionsWithBalance = sortedTransactions
        .map((transaction) => {
            runningBalance += transaction.amount;
            return { ...transaction, balance: runningBalance };
        })
        .reverse(); // Reverse to have the most recent transaction at the top
    return (
        <div className="history-wrapper">
            <div className="flex items-center justify-between">
                <h1 className="justify-between w-full">
                    <div className="flex items-center gap-x-2">
                        <Link to={basePath}>
                            <ArrowBackIosIcon fontSize="small" />
                        </Link>
                        <div>Spark Credit History</div>
                    </div>
                    <IconButton
                        onClick={() => {
                            const csvContent = generateCsvContent(
                                groupCreditSparkCredit,
                                accountId,
                            );
                            downloadCsv(csvContent, 'spark-credit-history.csv');
                        }}
                    >
                        <Download />
                    </IconButton>
                </h1>
            </div>

            {!groupSparkCreditIsReady && (
                <div className="spinner-wrapper">
                    <Spinner />
                </div>
            )}
            {groupSparkCreditIsReady && (
                <div className="history-item-wrapper flex flex-col gap-y-4 ">
                    {transactionsWithBalance.length ? (
                        transactionsWithBalance
                            .filter(({ creditType }) => creditType !== 'referral')
                            .map((data, i: number) => (
                                <SparkCreditHistoryItem data={data} key={i} accountId={accountId} />
                            ))
                    ) : (
                        <NoCreditHistory />
                    )}
                </div>
            )}
        </div>
    );
};
export default SparkCreditHistory;
