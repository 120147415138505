import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';

import moment, { Moment } from 'moment';

import { DayNumber, RecurringSchedule, RecurringScheduleOption } from '@sparkplug/lib';

import { RecurringSparkIcon } from '@components/icons';
import DatePicker from '@components/inputs/DatePicker';
import DateRangePicker from '@components/inputs/DateRangePicker';
import DayOfWeekPicker from '@components/inputs/DayOfWeekPicker';
import InputHelperText from '@components/inputs/InputHelperText';
import InputLabel from '@components/inputs/InputLabel';
import Select, { SelectOption } from '@components/inputs/Select';

import { useApp } from '@hooks/AppHooks';

import { appendClasses, uuid } from '@helpers/ui';

import './RecurringSchedulePicker.scss';

export interface RecurringSchedulePickerValue
    extends Partial<RecurringSchedule<RecurringScheduleOption>> {}

const DEFAULT_HELPER_TEXT: Record<RecurringScheduleOption, ReactElement> = {
    daily: (
        <>
            This will run for{' '}
            <u>
                <em>1 day</em>
            </u>
            , and recur on the selected days of the week.
        </>
    ),
    weekly: (
        <>
            This will run for{' '}
            <u>
                <em>7 days</em>
            </u>
            , and recur each week, Monday through Sunday.
        </>
    ),
    twice_monthly: (
        <>
            This will run for{' '}
            <u>
                <em>14 days</em>
            </u>{' '}
            twice a month, starting on the 1st and the 15th.
        </>
    ),
    monthly: (
        <>
            This will run for a{' '}
            <u>
                <em>full month</em>
            </u>
            , and recur on the 1st of each month.
        </>
    ),
};

const VALID_DATES: Record<
    'weekly' | 'twice_monthly' | 'monthly',
    {
        isValidStartDate: (date: Moment) => boolean;
        isValidEndDate: (date: Moment) => boolean;
        startDateOffset: (date: Moment) => Moment;
        endDateOffset: (date: Moment) => Moment;
    }
> = {
    weekly: {
        isValidStartDate: (date: Moment) => date.day() === 1,
        isValidEndDate: (date: Moment) => date.day() === 0,
        startDateOffset: (date: Moment) => date.clone().startOf('isoWeek'),
        endDateOffset: (date: Moment) => date.clone().endOf('isoWeek'),
    },
    twice_monthly: {
        isValidStartDate: (date: Moment) => [1, 15].includes(date.date()),
        isValidEndDate: (date: Moment) => [14, 28].includes(date.date()),
        startDateOffset: (date: Moment) =>
            date.date() > 14 ? date.clone().set('date', 15) : date.clone().startOf('month'),
        endDateOffset: (date: Moment) =>
            date.date() > 14 ? date.clone().set('date', 28) : date.clone().set('date', 14),
    },
    monthly: {
        isValidStartDate: (date: Moment) => date.date() === 1,
        isValidEndDate: (date: Moment) => date.date() === date.clone().endOf('month').date(),
        startDateOffset: (date: Moment) => date.clone().startOf('month'),
        endDateOffset: (date: Moment) => date.clone().endOf('month'),
    },
};

const SCHEDULE_OPTIONS: SelectOption<{}>[] = [
    {
        value: 'daily',
        label: 'Daily',
    },
    {
        value: 'weekly',
        label: 'Weekly',
    },
    {
        value: 'twice_monthly',
        label: 'Twice-monthly',
    },
    {
        value: 'monthly',
        label: 'Monthly',
    },
];

const ScheduleOptionLabel: FC<{ option: SelectOption<{}> }> = ({ option }) => {
    return (
        <div className="schedule-option-label">
            <h3>{option.label}</h3>
            <span>{option.description}</span>
        </div>
    );
};

interface RecurrenceFieldsProps extends RecurringSchedulePickerValue {
    calendarInfo?: string;
    updateValue: (updatedValue: Partial<RecurringSchedulePickerValue>) => void;
    minimumDaysOffset: number;
    disabled?: boolean;
}

const DailyRecurrenceFields: FC<RecurrenceFieldsProps> = ({
    startDate,
    updateValue,
    calendarInfo,
    minimumDaysOffset,
    disabled,
}) => {
    const onChange = (value: Moment | null) => {
        const dayOfTheWeek = value?.day();

        updateValue({
            startDate: value?.format('YYYY-MM-DD'),
            daysOfTheWeek: [dayOfTheWeek as DayNumber],
        });
    };

    const now =
        minimumDaysOffset > 0 ? moment().add(minimumDaysOffset, 'days').toDate() : new Date();

    return (
        <>
            <span className="recurring-schedule-primary-fields_text">starts on</span>
            <DatePicker
                calendarInfo={calendarInfo}
                id={uuid()}
                placeholder="Start date"
                disabled={disabled}
                value={startDate}
                onChange={onChange}
                isOutsideRange={(day) => day.isBefore(now, 'date')}
            />
        </>
    );
};

const DailyRecurrenceSecondaryFields: FC<RecurrenceFieldsProps> = ({
    daysOfTheWeek,
    updateValue,
    disabled,
}) => (
    <DayOfWeekPicker
        label="Days of the Week"
        value={daysOfTheWeek ?? []}
        variant="filled"
        disabled={disabled}
        onChange={(value) =>
            updateValue({
                daysOfTheWeek: value as DayNumber[],
            })
        }
    />
);

const DateRangeFields: FC<RecurrenceFieldsProps> = ({
    interval,
    startDate,
    updateValue,
    calendarInfo,
    minimumDaysOffset,
    disabled,
}) => {
    const { userIsSuperAdmin } = useApp();
    const onChange = (updatedStartDate: Moment | null) => {
        updateValue({
            startDate: updatedStartDate?.format('YYYY-MM-DD'),
        });
    };

    const { isValidStartDate, isValidEndDate, startDateOffset, endDateOffset } =
        VALID_DATES[interval as 'weekly' | 'twice_monthly' | 'monthly'];

    const endDate = useMemo(
        () => (startDate ? endDateOffset(moment(startDate))?.format('YYYY-MM-DD') : null),
        [startDate],
    );

    const now = minimumDaysOffset > 0 ? moment().add(minimumDaysOffset, 'days') : new Date();

    const DayContents = ({ date }: { date: Moment }) => {
        const ref = useRef<HTMLDivElement>(null);
        const classNamesAppended = appendClasses([
            isValidStartDate(date) ? 'is-valid-start-date' : 'is-not-valid-start-date',
            isValidEndDate(date) ? 'is-valid-end-date' : 'is-not-valid-end-date',
            date.date() === date.clone().endOf('month').date() ? 'is-end-of-month' : '',
        ]);

        if (ref.current?.parentElement) {
            ref.current.parentElement.classList.add('day-contents-parent');
        }

        return (
            <div ref={ref} className={classNamesAppended}>
                <span>{date.date()}</span>
            </div>
        );
    };

    const appendDayModifiers = (
        modifiers: Set<string>,
        date: Moment | null | undefined,
    ): Set<string> => {
        const updatedModifiers = new Set(modifiers);
        const isHoveredOffset = updatedModifiers.has('hovered-offset');

        if (date && isHoveredOffset) {
            const isStartOfRange = isValidStartDate(date);
            const isEndOfRange = isValidEndDate(date);

            const rangeStartsBeforeToday = startDateOffset(date).isBefore(now, 'date');

            if (rangeStartsBeforeToday) {
                return new Set(['blocked', 'blocked-out-of-range']);
            }

            if (isStartOfRange) {
                updatedModifiers.add('selected-start');
            } else if (isEndOfRange) {
                updatedModifiers.add('selected-end');
            } else {
                updatedModifiers.add('selected-span');
            }
        }
        return updatedModifiers;
    };

    return (
        <>
            <span className="recurring-schedule-primary-fields_text">starts on</span>
            <DateRangePicker
                calendarInfo={calendarInfo}
                pickerClassName="recurring-schedule-picker"
                id={uuid()}
                dateStart={startDate}
                dateEnd={endDate}
                startDateOffset={startDateOffset}
                endDateOffset={endDateOffset}
                disabled={disabled ? true : 'endDate'}
                onApply={onChange}
                isOutsideRange={userIsSuperAdmin ? undefined : (date) => date.isBefore(now, 'date')}
                renderDayContents={(date) => <DayContents date={date} />}
                appendDayModifiers={appendDayModifiers}
            />
        </>
    );
};

type HelperTextMap = Partial<Record<RecurringScheduleOption, () => string | ReactElement>>;
type CalendarInfoMap = Partial<Record<RecurringScheduleOption, string>>;
interface RecurringSchedulePickerProps {
    name: string;
    required?: boolean;
    label?: string;
    defaultValue?: RecurringSchedulePickerValue;
    value?: RecurringSchedulePickerValue;
    disabled?: boolean;
    calendarInfo?: Partial<Record<RecurringScheduleOption, string>>;
    helperText?: Partial<Record<RecurringScheduleOption, () => string | ReactElement>>;
    recurringIcon?: ReactElement;
    minimumDaysOffset?: number;
    onChange: (updatedValue: RecurringSchedulePickerValue) => void;
}

const getHelperText = (
    helperTextMap: HelperTextMap | undefined,
    interval: RecurringScheduleOption | undefined,
): ReactElement | string | undefined => {
    if (interval) {
        if (helperTextMap?.[interval]) {
            return helperTextMap[interval]?.();
        }

        return DEFAULT_HELPER_TEXT[interval];
    }

    return <em>Select a recurring schedule</em>;
};

const getCalendarInfo = (
    helperTextMap: CalendarInfoMap | undefined,
    interval: RecurringScheduleOption | undefined,
): string | undefined => {
    if (interval) {
        if (helperTextMap?.[interval]) {
            return helperTextMap[interval];
        }
    }

    return undefined;
};

const RecurringSchedulePicker: FC<RecurringSchedulePickerProps> = ({
    name,
    required,
    label,
    value: controlledValue = {},
    disabled = false,
    calendarInfo: calendarInfoMap,
    helperText: helperTextMap = {},
    recurringIcon = <RecurringSparkIcon />,
    minimumDaysOffset = 0,
    onChange,
}) => {
    const [value, setValue] = useState(controlledValue);

    useEffect(() => {
        onChange(value);
    }, [value]);

    const updateValue = (updatedValue: Partial<RecurringSchedulePickerValue>) =>
        setValue((prevValue) => ({
            ...prevValue,
            ...updatedValue,
        }));

    const calendarInfo = useMemo(
        () => getCalendarInfo(calendarInfoMap, value.interval),
        [calendarInfoMap, value.interval],
    );

    const helperText = useMemo(
        () => getHelperText(helperTextMap, value.interval),
        [helperTextMap, value.interval],
    );

    const options = useMemo(
        () =>
            SCHEDULE_OPTIONS.map((option) => ({
                ...option,
                description: getHelperText(helperTextMap, option.value as RecurringScheduleOption),
            })),
        [helperTextMap],
    );

    return (
        <div className="recurring-schedule-picker form-control">
            {label && <InputLabel required={required}>{label}</InputLabel>}
            <div className="recurring-schedule-primary-fields">
                <Select
                    name={`${name}.interval`}
                    value={value.interval ?? ''}
                    options={options}
                    disabled={disabled}
                    onChange={(event) =>
                        setValue({
                            interval: event.target.value,
                        })
                    }
                    OptionLabel={ScheduleOptionLabel}
                />
                {value.interval === 'daily' && (
                    <DailyRecurrenceFields
                        {...value}
                        disabled={disabled}
                        minimumDaysOffset={minimumDaysOffset}
                        updateValue={updateValue}
                    />
                )}
                {!!value.interval && value.interval !== 'daily' && (
                    <DateRangeFields
                        {...value}
                        disabled={disabled}
                        minimumDaysOffset={minimumDaysOffset}
                        calendarInfo={calendarInfo}
                        updateValue={updateValue}
                    />
                )}
            </div>
            {value.interval === 'daily' && (
                <div className="recurring-schedule-secondary-fields">
                    <DailyRecurrenceSecondaryFields
                        {...value}
                        disabled={disabled}
                        minimumDaysOffset={minimumDaysOffset}
                        updateValue={updateValue}
                    />
                </div>
            )}
            {helperText && (
                <InputHelperText className="recurring-schedule-helper-text">
                    {!!value.interval && recurringIcon}
                    <span>{helperText}</span>
                </InputHelperText>
            )}
        </div>
    );
};

export default RecurringSchedulePicker;
