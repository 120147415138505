import { FC, useCallback, useMemo, useState } from 'react';

import clsx from 'clsx';
import moment from 'moment';

import { UserTransaction, formatCurrency } from '@sparkplug/lib';

import { useUserTransactionsQuery } from '@core/users/queries/UserQueries';

import CalloutMessage from '@components/layout/CalloutMessage';
import ClickableArea from '@components/layout/ClickableArea';
import Spinner from '@components/layout/Spinner';
import Tooltip from '@components/layout/Tooltip';
import { ExpandableTable } from '@components/table/ExpandableTable';
import Table from '@components/table/Table';
import toast from '@components/toast';
import Toolbar from '@components/toolbar/Toolbar';

import { useSearch } from '@hooks/UIHooks';

import Typography from '@app/componentLibrary/Typography';
import { ChartLeader } from '@app/types/ChartDataTypes';
import { THeadCell } from '@app/types/TableTypes';
import { IOption } from '@app/types/UITypes';

import Drawer from '../Drawer';

import './ViewUserSparkTransactionsDrawer.scss';

const getTransactionHeadCells = (): THeadCell<UserTransaction>[] => [
    {
        id: 'bucket',
        label: 'Transaction Date',
        sortType: 'date',
        render: (row) => <Table.Cell>{moment(row.bucket).format('LL')}</Table.Cell>,
    },
    {
        id: 'locationName',
        label: 'Location',
        sortType: 'string',
        render: (row) => <Table.Cell>{row.locationName}</Table.Cell>,
    },
    {
        id: 'numQualifyingItems',
        label: 'Qualifying Units',
        sortType: 'numeric',
        render: (row) => <Table.Cell>{row.numQualifyingItems}</Table.Cell>,
    },
    {
        id: 'finalTotal',
        label: 'Total',
        sortType: 'numeric',
        render: (row) => <Table.Cell>{formatCurrency(row.finalTotal, true)}</Table.Cell>,
    },
];

interface ViewUserSparkTransactionsDrawerProps {
    isVisible: boolean;
    onClose: () => void;
    sparkId?: string;
    selectedEmployee?: ChartLeader;
    startDate: string;
    endDate: string;
    userIsSuperAdmin: boolean;
}

interface UserTransactionsRow extends UserTransaction {
    key: string;
}

const getLocationOptionsFromTransactions = (transactionRows: UserTransactionsRow[]): IOption[] => {
    const locationMap = new Map<string, string>();

    transactionRows.forEach((row) => {
        if (row.locationId && row.locationName) {
            locationMap.set(row.locationId, row.locationName);
        }
    });

    return Array.from(locationMap)
        .map(([id, name]) => ({
            value: id,
            label: name,
            key: id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};

const handleCopyProductId = (productId: string) => {
    navigator.clipboard
        .writeText(productId)
        .then(() => toast.success('Product ID copied to clipboard'))
        .catch(() => toast.error('Failed to copy Product ID'));
};

const handleCopyTransactionId = (transactionId: string) => {
    navigator.clipboard
        .writeText(transactionId)
        .then(() => toast.success('Transaction ID copied to clipboard'))
        .catch(() => toast.error('Failed to copy Transaction ID'));
};
const handleCopyUserId = (userId: string) => {
    navigator.clipboard
        .writeText(userId)
        .then(() => toast.success('User ID copied to clipboard'))
        .catch(() => toast.error('Failed to copy User ID'));
};

interface ProductItemLineProps {
    product: UserTransaction['items'][0];
    userIsSuperAdmin: boolean;
    txnIsVoided: boolean;
}

const ProductItemLine: FC<ProductItemLineProps> = ({ product, userIsSuperAdmin, txnIsVoided }) => {
    const itemHasBeenVoided = txnIsVoided || product.voided;
    const voidedClass = itemHasBeenVoided ? 'voided' : '';
    return (
        <div className={clsx('flex flex-row', voidedClass)} key={product.sparkProductId}>
            <strong>({product.quantity})&nbsp;</strong>
            <Tooltip
                title={product.sparkProductId}
                placement="top-start"
                disabled={!userIsSuperAdmin}
            >
                <div>
                    <ClickableArea
                        onClick={() =>
                            userIsSuperAdmin && handleCopyProductId(product.sparkProductId)
                        }
                    >
                        <span>{product.productName}</span>
                        {itemHasBeenVoided && <span>&nbsp;(Voided)</span>}
                    </ClickableArea>
                </div>
            </Tooltip>
        </div>
    );
};

interface TransactionIdRowProps {
    label: string;
    id: string;
    onCopy: (id: string) => void;
}

const TransactionIdRow: FC<TransactionIdRowProps> = ({ label, id, onCopy }) => (
    <div className="grid grid-cols-[200px_1fr] gap-2 py-1">
        <span className="text-gray-800">{label}</span>
        <ClickableArea onClick={() => onCopy(id)}>{id}</ClickableArea>
    </div>
);

const ViewUserTransactionsTable: FC<{
    flexibleEmployeeId: string;
    sparkId: string;
    selectedEmployee: ChartLeader;
    userIsSuperAdmin: boolean;
}> = ({ flexibleEmployeeId, sparkId, selectedEmployee, userIsSuperAdmin }) => {
    const { transactions, transactionsAreReady } = useUserTransactionsQuery(
        sparkId,
        flexibleEmployeeId,
    );
    const [filters, setFilters] = useState<{ locations: IOption[]; showNonQualifying: boolean }>({
        locations: [],
        showNonQualifying: false,
    });

    const transactionRows: UserTransactionsRow[] = useMemo(
        () =>
            transactions.map((transaction) => ({
                ...transaction,
                key: transaction.id,
            })),
        [transactions],
    );

    const locationOptions = getLocationOptionsFromTransactions(transactionRows);

    const applyInventoryLocationFilter = useCallback(
        (rows: UserTransactionsRow[]) => {
            if (!filters.locations.length) return rows;
            const filteredRows = rows.filter((row) =>
                filters.locations.some((filter) => filter.value === row.locationId),
            );
            return filteredRows;
        },
        [filters.locations],
    );

    const applyQualifyingTransactionFilter = useCallback(
        (rows: UserTransactionsRow[]) => {
            if (filters.showNonQualifying) {
                return rows; // Show all rows
            }
            return rows.filter((row) => row.transactionQualifiesForSpark); // Show only qualifying rows
        },
        [filters.showNonQualifying],
    );

    const isAllLocationsSelected = useMemo(
        () =>
            locationOptions.length > 0 &&
            filters.locations &&
            filters.locations.length === locationOptions.length &&
            locationOptions.every((option) =>
                filters.locations?.some((location) => location.value === option.value),
            ),
        [locationOptions, filters.locations],
    );

    const showLocationsDropdown = useMemo(() => {
        return locationOptions.length > 1;
    }, [locationOptions]);

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch(
        ['items.productName', 'locationName', 'externalTransactionId'],
        false,
        '',
        ['finalTotal'],
    );

    const headCells = useMemo(() => getTransactionHeadCells(), []);

    const getCollapsibleContent = useCallback(
        (row: UserTransaction) => {
            if (!row?.items) {
                return <></>;
            }
            const [showNonQualifyingProducts, setShowNonQualifyingProducts] = useState(
                row.numQualifyingItems < 1,
            );

            const qualifyingProducts = row.items.filter((group) => group.qualifiesForSpark);
            const nonQualifyingProducts = row.items.filter((group) => !group.qualifiesForSpark);

            return (
                <div className="my-4 mx-2 bg-gray-0 rounded p-3">
                    {userIsSuperAdmin && (
                        <div className="border-[1px] border-solid border-[rgb(31,41,55)] p-2 mb-2 rounded-md">
                            <Typography variant="base" className="text-gray-950 font-bold">
                                Super-Admin Only:
                            </Typography>
                            <TransactionIdRow
                                label="Retailer TransactionId"
                                id={row.externalTransactionId}
                                onCopy={handleCopyTransactionId}
                            />
                            <TransactionIdRow
                                label="SparkPlug TransactionId"
                                id={row.id}
                                onCopy={handleCopyTransactionId}
                            />
                        </div>
                    )}
                    <div className="grid grid-cols-[200px_1fr] gap-4 py-2">
                        <span className="text-gray-800">Qualifying Products</span>
                        <div className="flex flex-col gap-2">
                            {qualifyingProducts.length > 0 ? (
                                <>
                                    {qualifyingProducts.map((product) => (
                                        <ProductItemLine
                                            key={product.sparkProductId}
                                            product={product}
                                            userIsSuperAdmin={userIsSuperAdmin}
                                            txnIsVoided={row.voided}
                                        />
                                    ))}
                                </>
                            ) : (
                                <span className="text-gray-400">None</span>
                            )}
                            {nonQualifyingProducts.length > 0 && (
                                <>
                                    <ClickableArea
                                        className="text-cerulean-600"
                                        onClick={() =>
                                            setShowNonQualifyingProducts(!showNonQualifyingProducts)
                                        }
                                    >
                                        {showNonQualifyingProducts ? 'Hide' : 'Show'}
                                        &nbsp;Non-Qualifying Products
                                    </ClickableArea>
                                    {showNonQualifyingProducts && (
                                        <>
                                            {nonQualifyingProducts.map((product) => (
                                                <ProductItemLine
                                                    key={product.sparkProductId}
                                                    product={product}
                                                    userIsSuperAdmin={userIsSuperAdmin}
                                                    txnIsVoided={row.voided}
                                                />
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            );
        },
        [selectedEmployee],
    );

    return (
        <>
            {!transactionsAreReady ? (
                <div className="w-full flex justify-center p-4">
                    <Spinner />
                </div>
            ) : (
                <>
                    <Toolbar className="[&_.toolbar]:justify-start">
                        <Toolbar.Search
                            name="transactions-search"
                            defaultValue={searchFilter}
                            onChange={onChangeSearchFilter}
                        />
                        {showLocationsDropdown && (
                            <Toolbar.DropdownListSelector
                                label={`${isAllLocationsSelected ? '(All) ' : ''}Locations`}
                                options={locationOptions}
                                selected={filters.locations || []}
                                onApply={(locations) => {
                                    setFilters({ ...filters, locations });
                                }}
                                showCount={!isAllLocationsSelected && filters.locations?.length > 0}
                                clear={{
                                    active: !!filters.locations?.length,
                                    onClear: () => {
                                        setFilters({ ...filters, locations: [] });
                                    },
                                }}
                                selectedCountProps={{
                                    pluralUnitLabel: 'locations',
                                }}
                            />
                        )}
                        <Toolbar.Checkbox
                            label="Show non-qualifying"
                            value={filters.showNonQualifying}
                            onChange={() =>
                                setFilters({
                                    ...filters,
                                    showNonQualifying: !filters.showNonQualifying,
                                })
                            }
                        />
                    </Toolbar>
                    <ExpandableTable
                        className="transactions-table mb-16"
                        variant="raised"
                        filters={[
                            applyInventoryLocationFilter,
                            applySearch,
                            applyQualifyingTransactionFilter,
                        ]}
                        defaultOptions={{
                            orderBy: 'bucket',
                            order: 'desc',
                        }}
                        rows={transactionRows}
                        headCells={headCells}
                        collapse={getCollapsibleContent}
                        showPagination
                        emptyStateText="No transactions found"
                    />
                </>
            )}
        </>
    );
};

export const ViewUserSparkTransactionsDrawer: FC<ViewUserSparkTransactionsDrawerProps> = ({
    isVisible,
    onClose,
    sparkId,
    selectedEmployee,
    startDate,
    endDate,
    userIsSuperAdmin,
}) => {
    return (
        <Drawer
            title={`${selectedEmployee?.name}'s Transactions`}
            variant="right"
            isVisible={isVisible}
            className="invite-edit-member-drawer [&_.MuiDrawer-paper]:max-w-[700px]"
            onCloseHandler={onClose}
        >
            <ClickableArea
                onClick={
                    userIsSuperAdmin
                        ? () => handleCopyUserId(selectedEmployee?.flexibleEmployeeId ?? '')
                        : undefined
                }
            >
                <CalloutMessage
                    className="mb-3"
                    message={
                        <span>
                            View this employee's transactions for this Spark period{' '}
                            <strong>
                                {moment(startDate).format('MMM D, YYYY')} -{' '}
                                {moment(endDate).format('MMM D, YYYY')}
                            </strong>
                            . Expand each row to view qualifying products sold.
                        </span>
                    }
                    color="neutral"
                />
            </ClickableArea>
            {selectedEmployee && sparkId && (
                <ViewUserTransactionsTable
                    flexibleEmployeeId={selectedEmployee.flexibleEmployeeId}
                    sparkId={sparkId}
                    selectedEmployee={selectedEmployee}
                    userIsSuperAdmin={userIsSuperAdmin}
                />
            )}
        </Drawer>
    );
};

export default ViewUserSparkTransactionsDrawer;
