import { useEffect } from 'react';

import { formatCurrency } from '@sparkplug/lib';

import { useGetAccountReachQuery } from '@features/account-links/queries/useGetAccountReach';
import { getAccountMarketOptions } from '@features/spark-brands/views/CCSparkBrandsView/CreateEditSparkBrandsModal/CreateEditSparkBrandsModal';

import Form from '@components/form/Form';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { useLearningResource } from '../../context/LearningResourceContext';

const LearningResourcesForm = () => {
    const [
        { selectedMarkets, description, marketOptions, reward, name, errors, budget, hasBudget },
        { setSelectedMarkets, setDescription, setReward, setName, setBudget, setHasBudget },
    ] = useLearningResource();

    const MarketOptions = getAccountMarketOptions(marketOptions);
    const { account } = useSparkplugAccount();
    const { data: reachData, isLoadingReach } = useGetAccountReachQuery({
        accountId: account?._id,
        markets: selectedMarkets,
    });

    useEffect(() => {
        setSelectedMarkets(marketOptions);
    }, [marketOptions]);

    return (
        <div className="p-4 flex flex-col gap-4">
            <div>
                <Form.TextField
                    error={!!errors.name && name.length === 0}
                    name="name"
                    data-testid="name-input"
                    label="Name"
                    value={name}
                    required
                    maxLength={32}
                    helperText={`${name?.length} / 32`}
                    className="w-full"
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div>
                <Form.TextField
                    name="description"
                    error={!!errors.description && description.length === 0}
                    data-testid="description-input"
                    label="Description"
                    value={description}
                    required
                    className="w-full"
                    maxLength={90}
                    helperText={`${description?.length} / 90`}
                    multiline
                    rows={3}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>

            <div>
                <Form.TextField
                    name="reward"
                    data-testid="reward-input"
                    required
                    error={!!errors.reward && reward === 0}
                    isMoneyField
                    moneyFieldIsActive
                    moneyFieldErrorMessage="Reward must be greater than 0"
                    type="number"
                    label="Reward Amount"
                    value={reward}
                    onChange={(e) => setReward(Number(e.target.value))}
                    className="mb-4 w-full"
                />
                {!hasBudget && reward && (reachData?.groupMembershipCount || 0) >= 0 ? (
                    <span className="text-sm text-gray-80 italic block mt-1">
                        Maximum estimated payout:{' '}
                        <span className="font-bold">
                            {formatCurrency(reward * (reachData?.groupMembershipCount || 0) * 1.2)}
                        </span>{' '}
                        (including platform fees)
                    </span>
                ) : (
                    <></>
                )}
            </div>
            <div>
                <Form.Checkbox
                    label="Set course budget"
                    value={hasBudget}
                    onChange={(e: { target: { checked: boolean } }) => {
                        setHasBudget(e.target.checked);
                    }}
                />
            </div>
            {hasBudget && (
                <div>
                    <Form.TextField
                        name="budget"
                        data-testid="budget-input"
                        required={hasBudget}
                        error={!!errors.budget && budget === 0}
                        isMoneyField
                        moneyFieldIsActive
                        moneyFieldErrorMessage="Budget must be greater than 0 and reward amount"
                        type="number"
                        label="Course Budget"
                        value={budget}
                        onChange={(e) => {
                            const newValue = Number(e.target.value);
                            if (!Number.isNaN(newValue)) {
                                setBudget(Number(e.target.value));
                            }
                        }}
                        className="mb-4 w-full"
                    />
                    {errors.budget && (
                        <span className="text-sm text-sienna-500 italic block mt-1">
                            {errors.budget}
                        </span>
                    )}
                    <span className="text-sm text-gray-80 italic block mt-1">
                        This course will be automatically unpublished once your total budget is
                        reached. You can always increase your budget and republish.
                    </span>
                </div>
            )}
        </div>
    );
};

export default LearningResourcesForm;
