import { ComponentProps, PropsWithChildren, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import {
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    List as MaterialUIList,
    ListItem as MaterialUIListItem,
} from '@mui/material';

import { appendClasses } from '@helpers/ui';

import './List.scss';

type ListItemProps = Omit<ComponentProps<typeof MaterialUIListItem>, 'button' | 'selected'> & {
    to?: string;
    button?: boolean;
    selected?: boolean;
};

const ListItem = ({
    className,
    children,
    button = false,
    selected = false,
    onClick = undefined,
    to = undefined,
    ...otherProps
}: ListItemProps) => {
    const classNameAppended = appendClasses([className, 'list-item']);

    const dataQaProps: any = {};

    Object.keys(otherProps)
        .filter((propKey) => {
            return propKey.includes('data-qa');
        })
        .forEach((propKey: any) => {
            dataQaProps[propKey] = otherProps[propKey as keyof typeof otherProps];
        });

    const props = {
        className: classNameAppended,
        disableGutters: true,
        selected,
        button: button!,
        onClick,
        to,
        component: to ? Link : undefined,
        ...dataQaProps,
    };

    return <MaterialUIListItem {...props}>{children}</MaterialUIListItem>;
};

type ListProps = PropsWithChildren<{
    className?: string;
}>;

const List = forwardRef<HTMLUListElement, ListProps>(({ className, children }, listRef) => {
    const classNameAppended = appendClasses([className, 'list']);

    return (
        <MaterialUIList ref={listRef} className={classNameAppended} component="ul">
            {children}
        </MaterialUIList>
    );
});

export default Object.assign(List, {
    Item: ListItem,
    ItemIcon: ListItemIcon,
    ItemText: ListItemText,
    ItemSecondaryAction: ListItemSecondaryAction,
});
