import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { SurveyCreator } from 'survey-creator-react';

import { AccountMarket, ILearningResource } from '@sparkplug/lib';

import { useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';

import { IAccount } from '@app/types/AccountsTypes';

import { useCreateLearningResource } from '../mutations/useCreateLearningResource';
import { useUpdateLearningResource } from '../mutations/useUpdateLearningResource';
import { useGetCourse } from '../queries/useGetCourses';

interface FormState {
    accountId: string;
    selectedMarkets: AccountMarket[];
    description: string;
    name: string;
    reward: number;
    brandId: string;
    internalNotes: string;
    billingMode: 'auto' | 'manual';
    hasBudget: boolean;
    budget: number;
}

interface LearningResourceState extends FormState {
    marketOptions: AccountMarket[];
    errors: { [key: string]: string };
    hasUnsavedChanges: boolean;
    isReviewing: boolean;
    isSaving: boolean;
}

interface LearningResourceActions {
    setAccountId: (id: string) => void;
    setSelectedMarkets: (markets: AccountMarket[]) => void;
    setDescription: (description: string) => void;
    setName: (name: string) => void;
    setReward: (reward: number | string) => void;
    setBrandId: (id: string) => void;
    setErrors: (errors: any) => void;
    setInternalNotes: (notes: string) => void;
    setBillingMode: (mode: 'auto' | 'manual') => void;
    setHasBudget: (hasBudget: boolean) => void;
    setBudget: (budget: number) => void;
    handleSaveAsDraft: (surveyCreator: SurveyCreator) => Promise<void>;
    handleSaveAsPublished: (surveyCreator: SurveyCreator) => Promise<void>;
    handleSaveAsUnpublished: (surveyCreator: SurveyCreator) => Promise<void>;
    handleReviewCourse: (value: boolean) => Promise<boolean>;
    handleSaveCourse: (
        surveyCreator: SurveyCreator,
        isDraft?: boolean,
        isUnpublished?: boolean,
    ) => Promise<ILearningResource | boolean>;
}

type LearningResourceContextType = [LearningResourceState, LearningResourceActions];

// This context is used by admin and super-admin UI
const LearningResourceContext = createContext<LearningResourceContextType | undefined>(undefined);

export const LearningResourceProvider: React.FC<{
    children: React.ReactNode;
    // A account id provided by the sparkplug account context.
    // Only used in admin app and not in the control center app.
    sparkplugAccount?: IAccount;
}> = ({ children, sparkplugAccount }) => {
    const [marketOptions, setMarketOptions] = useState<AccountMarket[]>([]);
    const [isReviewing, setIsReviewing] = useState(false);
    const [errors, setErrors] = useState<{
        accountId: string;
        brandId?: string;
        markets: string;
        reward: string;
        name: string;
        description: string;
    }>({
        accountId: '',
        brandId: '',
        markets: '',
        reward: '',
        name: '',
        description: '',
    });
    const [formState, setFormState] = useState<FormState>(() => {
        const initial = {
            accountId: sparkplugAccount?._id || '',
            selectedMarkets: [],
            reward: 0,
            brandId: '',
            description: '',
            name: '',
            internalNotes: '',
            billingMode: 'auto' as const,
            budget: 0,
            hasBudget: false,
        };
        return initial;
    });

    const setAccountId = (id: string) => setFormState((prev) => ({ ...prev, accountId: id }));
    const setSelectedMarkets = (markets: AccountMarket[]) =>
        setFormState((prev) => ({ ...prev, selectedMarkets: markets }));
    const setReward = (value: number | string) => {
        // Guard against NaN and invalid values
        if (value === undefined || value === null || value === '') {
            return;
        }

        const numericValue = typeof value === 'string' ? parseFloat(value) : value;

        // Guard against NaN after conversion
        if (Number.isNaN(numericValue)) {
            return;
        }

        setFormState((prev) => {
            // Additional guard in the state update
            const newReward = Number.isNaN(numericValue) ? prev.reward : numericValue;
            return { ...prev, reward: newReward };
        });
    };
    const setBrandId = (id: string) => setFormState((prev) => ({ ...prev, brandId: id }));
    const setDescription = (value: string) =>
        setFormState((prev) => ({ ...prev, description: value }));
    const setName = (value: string) => setFormState((prev) => ({ ...prev, name: value }));
    const setInternalNotes = (value: string) =>
        setFormState((prev) => ({ ...prev, internalNotes: value }));
    const setBillingMode = (value: 'auto' | 'manual') =>
        setFormState((prev) => ({ ...prev, billingMode: value }));
    const setBudget = (value: number) => setFormState((prev) => ({ ...prev, budget: value }));
    const setHasBudget = (value: boolean) =>
        setFormState((prev) => ({ ...prev, hasBudget: value }));

    const { user, history } = useApp();
    const { learningResourceId } = useParams<{ learningResourceId: string }>();

    const { duplicateFrom } = useQueryParams();

    const { learningResource: existingResource, isLoadingLearningResource } = useGetCourse(
        duplicateFrom || learningResourceId,
    );

    const { mutateAsync: createLearningResource, isLoading: isCreating } =
        useCreateLearningResource();
    const { mutateAsync: updateLearningResource, isLoading: isUpdating } =
        useUpdateLearningResource(learningResourceId);

    const validateForm = () => {
        const newErrors = {
            accountId: formState.accountId ? '' : 'Account is required',
            ...(sparkplugAccount?.type === 'brand' && {
                brandId: formState.brandId ? '' : 'Brand ID is required',
            }),
            markets:
                formState.selectedMarkets.length > 0 ? '' : 'At least one market must be selected',
            reward: formState.reward > 0 ? '' : 'Reward must be greater than 0',
            name: formState.name ? '' : 'Name is required',
            description: formState.description ? '' : 'Description is required',
            budget:
                formState.hasBudget &&
                (formState.budget === 0 ||
                    (formState.budget ? formState.budget : 0) < formState.reward)
                    ? 'Budget must be greater than 0 and reward amount'
                    : '',
        };
        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === '');
    };

    useEffect(() => {
        if (formState.accountId) {
            const selectedAccount = user?.accounts.find(
                (account) => account._id === formState.accountId,
            );
            setMarketOptions(selectedAccount?.metaData?.markets || []);
        }
    }, [formState.accountId, user?.accounts]);

    // Track initial values for comparison
    const [initialState, setInitialState] = useState({
        accountId: '',
        selectedMarkets: [] as AccountMarket[],
        reward: 0,
        brandId: '',
        description: '',
        name: '',
        internalNotes: '',
        billingMode: 'auto',
        hasBudget: false,
        budget: 0,
    });

    useEffect(() => {
        if (existingResource) {
            const newState = {
                accountId: existingResource.accountId,
                selectedMarkets: existingResource.markets,
                reward: existingResource.reward ? existingResource.reward / 100 : 0,
                brandId: existingResource.brandId || '',
                description: existingResource.description || '',
                name: duplicateFrom
                    ? `${existingResource.name || ''} (copy)`.trim()
                    : existingResource.name || '',
                internalNotes: existingResource.internalNotes || '',
                billingMode: existingResource.billingMode || 'manual',
                hasBudget: Boolean(existingResource.budget && existingResource.budget > 0),
                budget: existingResource.budget ? existingResource.budget / 100 : 0,
            };
            setInitialState(newState);
            setFormState(newState);
        } else if (sparkplugAccount?._id && !formState.accountId) {
            const newState = { ...formState, accountId: sparkplugAccount?._id };
            setInitialState(newState);
            setFormState(newState);
        }
    }, [existingResource, sparkplugAccount, duplicateFrom, isLoadingLearningResource]);

    const hasUnsavedChanges = useMemo(() => {
        return (
            initialState.accountId !== formState.accountId ||
            JSON.stringify(initialState.selectedMarkets) !==
                JSON.stringify(formState.selectedMarkets) ||
            initialState.reward !== formState.reward ||
            initialState.brandId !== formState.brandId ||
            initialState.description !== formState.description ||
            initialState.name !== formState.name ||
            initialState.internalNotes !== formState.internalNotes ||
            initialState.billingMode !== formState.billingMode ||
            initialState.hasBudget !== formState.hasBudget ||
            initialState.budget !== formState.budget
        );
    }, [initialState, formState]);

    const handleSaveCourse = async (
        surveyCreator: SurveyCreator,
        isDraft?: boolean,
        isUnpublished?: boolean,
    ): Promise<boolean | ILearningResource> => {
        if (!validateForm()) {
            toast.error('Please fill in all required fields');
            return false;
        }

        try {
            const resourceData: {
                accountId: string;
                reward: number;
                markets: AccountMarket[];
                brandId: string;
                name: string;
                description: string;
                internalNotes: string;
                billingMode: 'auto' | 'manual';
                surveyData: any;
                themeData: any;
                budget: number;
                launchedAt?: Date;
                removedAt?: Date;
            } = {
                accountId: formState.accountId,
                reward: formState.reward * 100,
                markets: formState.selectedMarkets,
                brandId: formState.brandId,
                name: formState.name,
                description: formState.description,
                internalNotes: formState.internalNotes,
                billingMode: formState.billingMode,
                surveyData: surveyCreator ? JSON.parse(surveyCreator.text) : {},
                themeData: surveyCreator ? JSON.parse(JSON.stringify(surveyCreator.theme)) : {},
                budget: formState.hasBudget ? formState.budget * 100 : -1,
            };

            // Only include state-changing fields if explicitly requested
            if (isDraft !== undefined) {
                resourceData.launchedAt = isDraft ? undefined : new Date();
            }
            if (isUnpublished !== undefined) {
                resourceData.removedAt = !isDraft && isUnpublished ? new Date() : undefined;
            }

            if (learningResourceId) {
                await updateLearningResource(resourceData);
            } else {
                const result = await createLearningResource(resourceData);
                return result;
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    const handleReviewCourse = async (value: boolean) => {
        if (!validateForm()) {
            toast.error('Please fill in all required fields');
            return false;
        }

        // Preserve the current reward value
        const currentReward = formState.reward;
        setIsReviewing(value);

        // Ensure reward value stays the same
        setFormState((prev) => ({
            ...prev,
            reward: currentReward,
        }));

        return true;
    };

    const handleSaveAsDraft = async (surveyCreator: SurveyCreator) => {
        const saveSuccessful = await handleSaveCourse(surveyCreator, true);
        if (saveSuccessful) {
            history.push(`/${formState.accountId}/courses`);
        }
    };

    const handleSaveAsPublished = async (surveyCreator: SurveyCreator) => {
        const saveSuccessful = await handleSaveCourse(surveyCreator, false);
        if (saveSuccessful) {
            history.push(`/${formState.accountId}/courses`);
        }
    };
    const handleSaveAsUnpublished = async (surveyCreator: SurveyCreator) => {
        const saveSuccessful = await handleSaveCourse(surveyCreator, false, true);
        if (saveSuccessful) {
            history.push(`/${formState.accountId}/courses`);
        }
    };
    const state = useMemo(() => {
        return {
            ...formState,
            marketOptions,
            errors,
            isSaving: isCreating || isUpdating,
            hasUnsavedChanges,
            isReviewing,
        };
    }, [formState, marketOptions, errors, hasUnsavedChanges, isReviewing]);

    const actions = useMemo(
        () => ({
            setAccountId,
            setSelectedMarkets,
            setDescription,
            setName,
            setReward,
            setBrandId,
            setErrors,
            setInternalNotes,
            setBillingMode,
            setHasBudget,
            setBudget,
            handleSaveCourse,
            handleSaveAsDraft,
            handleSaveAsPublished,
            handleSaveAsUnpublished,
            handleReviewCourse,
        }),
        [
            handleSaveAsDraft,
            handleSaveAsPublished,
            handleSaveAsUnpublished,
            handleReviewCourse,
            formState.reward,
        ],
    );

    const value: LearningResourceContextType = [state, actions];

    return (
        <LearningResourceContext.Provider value={value}>
            {children}
        </LearningResourceContext.Provider>
    );
};

export const useLearningResource = () => {
    const context = useContext(LearningResourceContext);
    if (context === undefined) {
        throw new Error('useLearningResource must be used within a LearningResourceProvider');
    }
    return context;
};
