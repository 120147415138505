import { FC, MouseEventHandler, RefObject } from 'react';

import Button, { IButtonProps } from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { Clear, ExpandMore } from '@components/icons';

import { appendClasses } from '@helpers/ui';

import { withDropdown } from './DropdownContext';

interface IDropdownButtonProps extends IButtonProps {
    clear?: {
        active: boolean;
        onClear: () => void;
    };
    handleClick?: MouseEventHandler;
    hideExpandIcon?: boolean;
    buttonRef?: RefObject<HTMLButtonElement> | null;
}

const DropdownButton: FC<IDropdownButtonProps> = ({
    className,
    color = 'blue',
    variant = 'flat',
    disabled = false,
    handleClick,

    onClick = () => {},
    children,
    startIcon,
    clear,
    hideExpandIcon = false,
    buttonRef = null,
    ...btnProps
}) => {
    const isActive = clear?.active;
    const classNamesAppended = appendClasses([
        'dropdown-btn-wrapper',
        isActive ? 'dropdown-btn-is-active' : null,
        `dropdown-btn-color-${color}`,
        `dropdown-btn-variant-${variant}`,
        className,
    ]);

    return (
        <div className={classNamesAppended}>
            <Button
                {...btnProps}
                ref={buttonRef}
                className="dropdown-btn"
                color={color}
                variant={variant}
                onClick={(event: any) => {
                    handleClick?.(event);
                    onClick();
                }}
                disabled={disabled}
                startIcon={startIcon}
                endIcon={!hideExpandIcon ? <ExpandMore /> : undefined}
            >
                {children}
            </Button>
            {isActive && (
                <IconButton className="clear-btn" onClick={clear?.onClear}>
                    <Clear />
                </IconButton>
            )}
        </div>
    );
};

export default withDropdown(DropdownButton);
