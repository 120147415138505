import { FC } from 'react';

import Typography from '@app/componentLibrary/Typography';

export interface SparkOwnerProps {
    firstName: string;
    lastName: string;
}

export const SparkOwnerDisplay: FC<SparkOwnerProps> = ({ firstName, lastName }) => {
    return (
        <div className="flex items-center gap-1">
            <div className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-white">
                <span className="text-[10px] font-semibold leading-none">
                    {firstName.slice(0, 1)}
                    {lastName.slice(0, 1)}
                </span>
            </div>
            <Typography variant="base">{`${firstName} ${lastName}`}</Typography>
        </div>
    );
};
