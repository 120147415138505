import { ReactNode } from 'react';

import moment from 'moment';

import { Badge, BadgeColor } from '@app/componentLibrary/Badge';
import { Card, CardContent } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography';

import { MetadataItem } from './MetaDataItem';

interface EntityMetadata {
    label: string;
    value: string | Date | null | undefined | ReactNode;
    isDate?: boolean;
}

interface MetadataGroup {
    metadata: EntityMetadata[];
}

interface EntityDetailsCardProps {
    title: string;
    titleBadge?: {
        label: string;
        color: BadgeColor;
    };
    name: string;
    description?: string;
    status?: {
        label: string;
        color: BadgeColor;
    };
    imageUrl?: string;
    imageAlt?: string;
    metadataGroups: MetadataGroup[];
    actions?: ReactNode;
    className?: string;
    testId?: string;
}

export const EntityDetailsCard = ({
    title,
    titleBadge,
    name,
    description,
    status,
    imageUrl,
    imageAlt,
    metadataGroups,
    actions,
    className,
    testId,
}: EntityDetailsCardProps) => {
    const formatDate = (date: string | Date) => (date ? moment(date).format('MMM D, YYYY') : '--');

    return (
        <Card className={className} data-testid={testId}>
            <CardContent className="p-4">
                <div className="lg:flex w-full">
                    <div className="flex-1 lg:border-r border-solid border-gray-100 pr-3">
                        <div className="flex w-full items-center">
                            {imageUrl && (
                                <img
                                    src={imageUrl}
                                    alt={imageAlt || ''}
                                    className="w-16 h-16 rounded-full mr-3"
                                />
                            )}
                            <div className="flex  lg:flex-1 gap-2 items-center">
                                <div className="w-full mr-10 lg:mr-0">
                                    <div className="flex items-center gap-2 mb-2">
                                        <Typography
                                            variant="sm"
                                            className="uppercase font-semibold text-gray-700"
                                        >
                                            {title}
                                        </Typography>
                                        {titleBadge?.label && (
                                            <Badge
                                                label={titleBadge.label}
                                                color={titleBadge.color}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <Typography variant="2xl" className="font-bold">
                                            {name}
                                        </Typography>
                                        {description && (
                                            <Typography variant="sm">{description}</Typography>
                                        )}
                                    </div>
                                </div>

                                {status && (
                                    <Badge label={status.label} color={status.color} size="lg" />
                                )}
                            </div>
                        </div>
                    </div>
                    {metadataGroups.map((group, groupIndex) => (
                        <div
                            key={groupIndex}
                            className={`grid mt-10 lg:mt-0 lg:grid-flow-col grid-cols-3 lg:grid-cols-1 lg:grid-rows-2 gap-x-8 gap-y-4 pl-6 pr-3 
                                ${
                                    groupIndex < metadataGroups.length - 1
                                        ? 'lg:border-r border-solid border-gray-100'
                                        : ''
                                }`}
                        >
                            {group.metadata.map((item, index) => (
                                <MetadataItem
                                    key={index}
                                    label={item.label}
                                    value={
                                        item.isDate ? (
                                            formatDate(item.value as Date)
                                        ) : (
                                            <>{item.value}</>
                                        )
                                    }
                                />
                            ))}
                        </div>
                    ))}
                    {actions && <div className="flex items-center justify-center">{actions}</div>}
                </div>
            </CardContent>
        </Card>
    );
};
