/* eslint-disable no-nested-ternary */
import { FC, ReactElement } from 'react';

import { Menu } from '@componentLibrary';

import { SparkDisplayStatus } from '@sparkplug/lib';

import CountChip from '@components/chips/CountChip';
import { Copy, Inbox as InboxIcon, Send as SendIcon } from '@components/icons';
import SparkStatusIcon from '@components/sparks/SparkStatusIcon';

import './SparkStatusButtons.scss';

export const StatusOptions = [
    {
        label: 'Active',
        value: 'active',
        to: '/sparks',
        startIcon: <SparkStatusIcon status="active" />,
    },
    {
        label: 'Upcoming',
        value: 'upcoming',
        to: '/sparks/upcoming',
        startIcon: <SparkStatusIcon status="upcoming" />,
    },
    {
        label: 'Complete',
        value: 'complete',
        to: '/sparks/complete',
        startIcon: <SparkStatusIcon status="completed" />,
    },
] as const;

type CoreStatusOption = (typeof StatusOptions)[number]['value'];

const AdditionalOptions = [
    {
        value: 'inbox/sent',
    },
] as const;

const VendorOptions = [
    {
        value: 'inbox/requests',
    },
] as const;

type Option = {
    value: string;
    to?: string;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    label?: string;
};

const getInboxOverrideData = (
    isVendorAccount: boolean,
    path: string,
    inboxCount?: number,
): {
    startIcon: ReactElement;
    endIcon?: ReactElement;
    label: string;
    to: string;
} => {
    const iconClassName = 'status-icon icon-color-blue h-[15px] w-[15px]';
    const isRequestInfo = path.includes('requests');
    const vendorTo = isRequestInfo ? '/sparks/requests?status=open' : '/sparks/sent';

    return {
        startIcon:
            isVendorAccount && !isRequestInfo ? (
                <SendIcon className={iconClassName} />
            ) : (
                <InboxIcon className={iconClassName} />
            ),
        endIcon: inboxCount ? <CountChip count={inboxCount} /> : undefined,
        label: isVendorAccount && !isRequestInfo ? 'Sent' : 'Inbox',
        to: isVendorAccount ? vendorTo : '/sparks/inbox',
    };
};

type OverrideOptions = {
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    label?: string;
};

interface SparkStatusButtonsProps {
    onSelect: (v: Option) => void;
    value: SparkDisplayStatus | 'templates';
    isBrand: boolean;
    unclaimedCount?: number;
    inboxCount?: number;
    requestCount?: number;
    isAdmin?: boolean;
    connectEnabled?: boolean;
    menuDirection?: 'horizontal' | 'vertical';
}

export const SparkStatusButtons: FC<SparkStatusButtonsProps> = ({
    onSelect,
    value,
    isBrand: isVendorAccount,
    unclaimedCount = 0,
    inboxCount,
    requestCount = 0,
    isAdmin = false,
    connectEnabled = true,
    menuDirection = 'vertical',
}) => {
    const optionOverrideMap = {} as Record<CoreStatusOption, OverrideOptions>;

    if (unclaimedCount > 0) {
        optionOverrideMap.complete = {
            endIcon: <CountChip count={unclaimedCount} />,
        };
    }

    const options = [
        ...(isVendorAccount && import.meta.env.REACT_APP_REQUEST_FOR_SPARK === 'true'
            ? VendorOptions
            : []),
        ...AdditionalOptions,
    ];
    const showTemplatesTab = isAdmin;

    return (
        <>
            <div className="spark-view__status-buttons max-sm:flex max-sm:flex-row max-sm:overflow-x-auto">
                <Menu
                    navigation={[
                        ...options.map((option) => {
                            const mergedOption = {
                                ...option,
                                ...getInboxOverrideData(
                                    isVendorAccount,
                                    option.value,
                                    option.value === 'inbox/requests' ? requestCount : inboxCount,
                                ),
                            };
                            return {
                                name: mergedOption.label,
                                current: value === mergedOption.value,
                                icon: mergedOption.startIcon,
                                badgeCount:
                                    mergedOption.value === 'inbox/requests'
                                        ? requestCount
                                        : inboxCount,
                                isHidden: !(isAdmin && connectEnabled),
                                onClick: () => {
                                    if (onSelect) {
                                        onSelect(mergedOption);
                                    }
                                },
                            };
                        }),
                        {
                            type: 'hr',
                        },
                        ...StatusOptions.map((option) => {
                            const mergedOption = {
                                ...option,
                                ...optionOverrideMap[option.value],
                            };
                            return {
                                name: mergedOption.label,
                                current: value === mergedOption.value,
                                icon: mergedOption.startIcon,
                                badgeCount:
                                    mergedOption.value === 'complete' && unclaimedCount > 0
                                        ? unclaimedCount
                                        : undefined,
                                onClick: () => {
                                    if (onSelect) {
                                        onSelect(mergedOption);
                                    }
                                },
                            };
                        }),
                        {
                            type: 'hr',
                        },
                        {
                            name: 'Templates',
                            current: value === 'templates',
                            icon: <Copy className="icon-color-blue w-[14px]" />,
                            isHidden: !showTemplatesTab,
                            onClick: () => {
                                onSelect({
                                    value: 'templates',
                                    to: `/sparks/templates`,
                                    startIcon: <Copy className="icon-color-blue w-[14px]" />,
                                    label: 'Templates',
                                });
                            },
                        },
                    ]}
                    menuDirection={menuDirection}
                />
            </div>
        </>
    );
};

export default SparkStatusButtons;
