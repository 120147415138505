import { useParams } from 'react-router-dom';

import { LearningResourceStatus } from '@sparkplug/lib';

import { useGetAccountReachQuery } from '@features/account-links/queries/useGetAccountReach';
import CourseResponses from '@features/courses/components/CourseAnalytics/CourseResponses';
import { useGetCourse, useGetSurveyResponses } from '@features/courses/queries/useGetCourses';

import { EntityDetailsCard } from '@components/EntityDetails/EntityDetailsCard';
import TruncatedMarketsList from '@components/TruncatedMarketsList';
import { NoCourseResponses } from '@components/graphics/EmptyStates';
import { Edit as EditIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import PageHeader from '@components/layout/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { BadgeColor } from '@app/componentLibrary/Badge';
import { Card } from '@app/componentLibrary/Card';

import { CourseCompletionCard } from './components/CourseCompletionCard';
import { CourseSummaryCard } from './components/CourseSummaryCard';

const AdminCoursesDetails = () => {
    const { account } = useSparkplugAccount();
    const { history } = useApp();
    const { learningResourceId } = useParams<{ learningResourceId: string }>();
    const { learningResource } = useGetCourse(learningResourceId);
    const { surveyResponses, isLoadingSurveyResponses } = useGetSurveyResponses(learningResourceId);
    const { data: reachData } = useGetAccountReachQuery({
        accountId: account?._id,
        markets: learningResource?.markets,
    });

    const metadataGroups = [
        {
            metadata: [
                {
                    label: 'Created Date',
                    value: learningResource?.createdAt,
                    isDate: true,
                },

                {
                    label: 'Last Edited',
                    value: learningResource?.updatedAt,
                    isDate: true,
                },
                {
                    label: 'Last Published',
                    value: learningResource?.launchedAt,
                    isDate: true,
                },
                {
                    label: 'Last Unpublished',
                    value: learningResource?.removedAt,
                    isDate: true,
                },
            ],
        },
        {
            metadata: [
                {
                    label: 'Audience',
                    value: (
                        <TruncatedMarketsList
                            markets={learningResource?.markets}
                            limit={3}
                            totalMarkets={account?.metaData?.markets?.length}
                        />
                    ),
                    isCustomValue: true,
                },
                {
                    label: 'Reach',
                    value: `${reachData?.groupMembershipCount} Employees at ${reachData?.accountLinkCount} Retailers`,
                },
            ],
        },
    ];

    return (
        <div>
            <PageHeader
                title={[
                    {
                        url: `/${account?._id}/courses/`,
                        title: `${account?.name} Courses`,
                    },
                    learningResource?.name || 'Course Details',
                ]}
                metaTitle={`${learningResource?.name} | Course Details`}
            >
                <Toolbar>
                    <Toolbar.Button
                        variant="filled"
                        color="blue"
                        startIcon={<EditIcon />}
                        onClick={() => {
                            history.push(`/${account?._id}/courses/${learningResourceId}/edit`);
                        }}
                    >
                        Edit Course
                    </Toolbar.Button>
                </Toolbar>
            </PageHeader>
            {/* Reward Summary Card */}
            <EntityDetailsCard
                className="mb-4"
                title="Course"
                titleBadge={{
                    label: learningResource?.brandName || '',
                    color: 'blue',
                }}
                name={learningResource?.name || ''}
                data-testid="course-details-name"
                description={learningResource?.description}
                status={{
                    label: learningResource?.status || '',
                    color: (
                        {
                            published: 'green',
                            draft: 'yellow',
                            unpublished: 'neutral',
                        } as Record<LearningResourceStatus, BadgeColor>
                    )[learningResource?.status as LearningResourceStatus],
                }}
                imageUrl={learningResource?.brandPhoto || ''}
                imageAlt={learningResource?.name || ''}
                metadataGroups={metadataGroups}
            />
            <CourseSummaryCard learningResource={learningResource} />
            {!isLoadingSurveyResponses && surveyResponses && surveyResponses.length > 0 && (
                <>
                    <CourseCompletionCard />
                    <CourseResponses
                        surveyId={learningResource?.surveyId || ''}
                        learningResourceId={learningResourceId}
                    />
                </>
            )}
            {!surveyResponses ||
                (surveyResponses.length === 0 && (
                    <Card>
                        <div className="flex flex-col items-center justify-center bg-gray-0 h-[424px] rounded-lg">
                            <EmptyStateDisplay
                                graphic={<NoCourseResponses />}
                                label="Course results will display here once published."
                            />
                        </div>
                    </Card>
                ))}
        </div>
    );
};

export default AdminCoursesDetails;
