import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { ButtonGroup as MaterialUIButtonGroup } from '@mui/material';

import Button from '../Button';

import './ButtonGroup.scss';

export type TButtonGroupColor = 'neutral' | 'blue' | 'green' | 'red';
export type TButtonGroupVariant = 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';

export interface IButtonGroupProps {
    className?: string;
    color: TButtonGroupColor;
    variant: TButtonGroupVariant;
    children: React.ReactNode;
}

const ButtonGroup = ({
    className = '',
    color = 'neutral',
    variant = 'flat',
    children,
}: IButtonGroupProps) => {
    const classNameAppended = `${className} btn-group btn-group-color-${color} btn-group-variant-${variant}`;

    return <MaterialUIButtonGroup className={classNameAppended}>{children}</MaterialUIButtonGroup>;
};

ButtonGroup.Button = ({ className, ...otherProps }: any) => {
    return <Button className={[className, 'btn-group-btn'].join(' ')} {...otherProps} />;
};

export default ButtonGroup;
