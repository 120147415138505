import { SurveyCreator } from 'survey-creator-react';
import { Survey } from 'survey-react-ui';

import { Model } from 'survey-core';

import { FEES_FRACTION, formatCurrency } from '@sparkplug/lib';

import { useGetAccountReachQuery } from '@features/account-links/queries/useGetAccountReach';
import { useLearningResource } from '@features/courses/context/LearningResourceContext';

import CalloutMessage from '@components/layout/CalloutMessage';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { Card, CardHeader, CardTitle } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography/Typography';

const CoursePreviewView = ({ creator }: { creator: SurveyCreator }) => {
    const [{ marketOptions, selectedMarkets, reward, budget, hasBudget }] = useLearningResource();
    const { account } = useSparkplugAccount();
    const { data: reachData, isLoadingReach } = useGetAccountReachQuery({
        accountId: account?._id,
        markets: selectedMarkets,
    });
    const survey = new Model(creator.text);
    survey.mode = 'display';
    survey.applyTheme(creator.theme);

    const totalPayout = reachData?.groupMembershipCount
        ? reachData?.groupMembershipCount * reward
        : 0;

    const perUnitFee = reward * FEES_FRACTION;

    const totalFee = perUnitFee * (reachData?.groupMembershipCount || 0);

    const totalPossiblePayout = totalPayout + totalFee;
    const callOutContent = {
        brand: () => (
            <>
                Publishing this course makes it available to{' '}
                <span className="font-bold">
                    {new Intl.NumberFormat().format(reachData?.groupMembershipCount || 0)}
                </span>{' '}
                employees at {new Intl.NumberFormat().format(reachData?.accountLinkCount || 0)}{' '}
                retailers to earn rewards.
            </>
        ),
        retailer: () => (
            <>
                After publishing, changes will be live. <br />
                <span className="font-bold">Note: </span> Employees who have already completed this
                course cannot retake it or earn additional rewards.
            </>
        ),
    };
    return (
        <div>
            {/* Main Content */}
            <div className="max-w-6xl mx-auto px-4 py-8 flex gap-4">
                {/* Course Preview */}
                <Card className="flex-1">
                    <CardHeader className="mb-4">
                        <CardTitle>
                            <Typography variant="lg" className="text-gray-950 font-semibold">
                                Course Preview
                            </Typography>
                        </CardTitle>
                    </CardHeader>

                    <div className="flex justify-center items-center relative">
                        <div className="relative">
                            <div className="absolute top-[50px] left-[16px] w-[379px] h-[671px] overflow-auto">
                                <Survey model={survey} />
                            </div>
                            <svg
                                width="410"
                                height="770"
                                viewBox="0 0 410 770"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="1"
                                    y="1"
                                    width="408"
                                    height="768"
                                    rx="44"
                                    fill="white"
                                    stroke="#CCD3DE"
                                    strokeWidth="2"
                                />
                                <rect
                                    x="15.5"
                                    y="49.5"
                                    width="379"
                                    height="671"
                                    fill="white"
                                    stroke="#CCD3DE"
                                />
                            </svg>
                        </div>
                    </div>
                </Card>

                {/* Payout Summary */}
                <div className="max-w-[374px]">
                    <Card className="p-4">
                        <CardHeader className="mb-4">
                            <CardTitle>
                                <Typography variant="lg" className="text-gray-950 font-semibold">
                                    Payout Summary
                                </Typography>
                            </CardTitle>
                        </CardHeader>

                        {!isLoadingReach && (
                            <CalloutMessage message={callOutContent[account?.type || 'brand']()} />
                        )}
                        <div className="space-y-4 pt-4">
                            <div className="flex justify-between items-center">
                                <Typography variant="base" className="text-gray-950 font-medium">
                                    Audience
                                </Typography>
                                <Typography variant="base">
                                    {selectedMarkets.length === marketOptions.length
                                        ? 'All Markets'
                                        : selectedMarkets.map((market) => market).join(', ')}
                                </Typography>
                            </div>

                            <div className="flex justify-between items-center">
                                <Typography variant="base" className="text-gray-950 font-medium">
                                    Employees
                                </Typography>
                                <Typography variant="base">
                                    {' '}
                                    {new Intl.NumberFormat().format(
                                        reachData?.groupMembershipCount || 0,
                                    )}
                                </Typography>
                            </div>

                            <div className="flex justify-between items-center">
                                <Typography variant="base" className="text-gray-950 font-medium">
                                    Reward Amount
                                </Typography>
                                <Typography variant="base">{formatCurrency(reward)}</Typography>
                            </div>

                            <div className="flex justify-between items-center">
                                <Typography variant="base" className="text-gray-950 font-medium">
                                    Platform Fee (20%)
                                </Typography>
                                <Typography variant="base">{formatCurrency(perUnitFee)}</Typography>
                            </div>

                            {hasBudget && (
                                <div className="flex justify-between items-center">
                                    <Typography
                                        variant="base"
                                        className="text-gray-950 font-medium"
                                    >
                                        Course Budget
                                    </Typography>
                                    <Typography variant="base">{formatCurrency(budget)}</Typography>
                                </div>
                            )}

                            <div className="text-sm text-gray-500 bg-gray-0 rounded-lg py-4 px-1 text-center font-medium mb-4">
                                Payout progress will display after publishing.
                            </div>

                            <div className=" text-sm">
                                <Typography variant="sm" className="text-gray-800 italic">
                                    Maximum potential payout:{' '}
                                    <span className="font-semibold">
                                        {formatCurrency(totalPossiblePayout)}
                                    </span>{' '}
                                    (including platform fees)
                                </Typography>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default CoursePreviewView;
