import moment from 'moment';

import { AccountType, UIEvent } from '@sparkplug/lib';

export type EventDisplayStatus =
    | 'complete'
    | 'active'
    | 'expired'
    | 'pending'
    | 'declined'
    | 'upcoming';

export const getEventDisplayStatus = ({
    event,
    accountType,
}: {
    event: UIEvent;
    accountType?: AccountType;
}): EventDisplayStatus => {
    const isEventExpired =
        event.isExpired ||
        (event.requestStatus === 'pending' && moment(event.startTime).isBefore(moment(), 'minute'));

    const isEventApproved =
        accountType === 'brand'
            ? event.requestStatus === 'approved'
            : !event.requestStatus || event.requestStatus === 'approved';

    const isEventComplete = isEventApproved && moment(event.endTime).isBefore(moment(), 'minute');

    if (isEventExpired) return 'expired';
    if (event.requestStatus === 'pending') return 'pending';
    if (event.requestStatus === 'declined') return 'declined';

    if (isEventApproved) {
        if (isEventComplete) return 'complete';

        const now = moment();
        // const isToday = moment(event.startTime).isSame(now, 'day');
        const isInProgress = now.isBetween(event.startTime, event.endTime, 'minute', '[]');

        if (isInProgress) return 'active';

        const daysUntilStart = moment(event.startTime).diff(now.startOf('day'), 'days');
        if (daysUntilStart > 0) return 'upcoming';
    }

    return 'pending'; // fallback status
};
