/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { SurveyCreator } from 'survey-creator-react';

import { Action, Question } from 'survey-core';
import { ElementGetActionsEvent, SurveyCreatorModel, UploadFileEvent } from 'survey-creator-core';

import { AdminCourseReview } from '@features/courses/components/AdminCourseReview';
import AdminCoursesForm from '@features/courses/components/CoursesForm/CoursesForm';
import { CourseStatus } from '@features/courses/types';
import { useGetSurvey } from '@features/learn-and-earn/queries/useGetSurvey';

import Button from '@components/buttons/Button';
import coursesGraphic from '@components/icons/CoursesGraphic.svg';
import FullscreenNav from '@components/layout/FullscreenNav';
import ConfirmModal from '@components/overlays/ConfirmModal';
import { useParams, useQueryParams } from '@components/router';
import toast from '@components/toast';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { Badge } from '@app/componentLibrary/Badge';
import Typography from '@app/componentLibrary/Typography';

import CourseAssetsModal from '../../components/CourseAssetsModal';
import {
    LearningResourceProvider,
    useLearningResource,
} from '../../context/LearningResourceContext';
import { useUploadCourseAsset } from '../../mutations/useUploadCourseAsset';
import { useGetCourse } from '../../queries/useGetCourses';
import { getCourseActionButtons, validateFile } from './utils';

import './styles.css';

const creatorOptions = {
    showLogicTab: false,
    showJSONEditorTab: false,
    isAutoSave: false,

    showSurveyTitle: false,
    showThemeTab: true,
    showDesignerTab: true,
    previewShowResults: false,
    showInvisibleElementsInPreviewTab: false,
    questionTypes: [
        'boolean',
        'checkbox',
        'comment',
        'dropdown',
        // tagbox is a multi-select dropdown
        'tagbox',
        'panel',
        'radiogroup',
        'rating',
        'ranking',
        'text',
        'image',
        'html',
    ],
};
type ConfirmationType = 'unsavedChanges' | 'unpublish';

const blackList = ['choicesByUrl', 'saveMmaskaskedValue', 'maskType', 'maskSettings'];

const AdminCoursesCreate = () => {
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const { account } = useSparkplugAccount();
    const { learningResourceId } = useParams<{ learningResourceId: string }>();
    const { enableGallery, duplicateFrom } = useQueryParams();
    const { learningResource: existingResource } = useGetCourse(learningResourceId);
    const { survey } = useGetSurvey(existingResource?.surveyId || '');
    const { mutateAsync: uploadCourseAsset } = useUploadCourseAsset(account?._id || '');
    const [
        { name: courseName, hasUnsavedChanges, isReviewing, isSaving },
        {
            handleSaveAsDraft,
            handleSaveAsPublished,
            handleSaveAsUnpublished,
            handleReviewCourse,
            handleSaveCourse,
        },
    ] = useLearningResource();
    const { history } = useApp();
    const [confirmationType, setConfirmationType] = useState<ConfirmationType | null>(null);

    const creatorRef = useRef<SurveyCreator | null>(null);

    if (!creatorRef.current) {
        creatorRef.current = new SurveyCreator({
            ...creatorOptions,
            previewOrientation: 'portrait',
        });
    }

    const creator = creatorRef.current;
    useAppBodyClasses(['sjs-creator-container']);

    useEffect(() => {
        if (existingResource && survey) {
            creator.text = JSON.stringify(survey.surveyData || {});
            if (survey.themeData) {
                creator.theme = survey.themeData;
            }
        }
    }, [existingResource, survey, creator]);

    const createGalleryAction = (question: Question) => {
        return new Action({
            id: 'gallery',
            title: 'Gallery',
            action: (context: any) => {
                setShowGalleryModal(true);
            },
            visibleIndex: 9,
        });
    };

    useEffect(() => {
        if (!creator) return () => {};
        const onShowingPropertyHandler = (_: any, options: any) => {
            if (options.obj.getType() === 'dropdown' || options.obj.getType() === 'tagbox') {
                options.canShow = blackList.indexOf(options.property.name) === -1;
            }

            if (options.obj.getType() === 'text') {
                options.canShow = blackList.indexOf(options.property.name) === -1;
            }
        };

        const onSurveyInstanceCreatedHandler = (
            _: any,
            { area, obj, survey: surveyInstance }: any,
        ) => {
            if (area === 'property-grid') {
                const logicCategory = surveyInstance?.getPanelByName('logic');
                logicCategory.visible = false;

                const dataPanel = surveyInstance?.getPanelByName('data');
                if (dataPanel) {
                    dataPanel.title = 'Answers';
                }
            }
        };

        const onQuestionAddedHandler = (sender: any, options: any) => {
            options.question.isRequired = true;
        };
        const onDefineElementMenuItems = (
            _: SurveyCreatorModel,
            options: ElementGetActionsEvent,
        ) => {
            if (!enableGallery) return;

            const question = options.obj;

            const questionType = question.getType();
            if (questionType === 'image') {
                const galleryAdorner = createGalleryAction(question as Question);
                options.items.push(galleryAdorner);
            }
        };

        creator.onDefineElementMenuItems.add(onDefineElementMenuItems);
        creator.onShowingProperty.add(onShowingPropertyHandler);
        creator.onSurveyInstanceCreated.add(onSurveyInstanceCreatedHandler);
        creator.onQuestionAdded.add(onQuestionAddedHandler);

        return () => {
            creator.onShowingProperty.remove(onShowingPropertyHandler);
            creator.onSurveyInstanceCreated.remove(onSurveyInstanceCreatedHandler);
            creator.onQuestionAdded.remove(onQuestionAddedHandler);
            creator.onDefineElementMenuItems.remove(onDefineElementMenuItems);
        };
    }, [creator]);

    useEffect(() => {
        if (!creator) return () => {};

        const onUploadFile = (_: any, options: UploadFileEvent) => {
            if (!account?._id) {
                toast.error('Please select an account before uploading a file');
                options.callback('error');
                return;
            }

            const validation = validateFile(options.files[0]);
            if (!validation.valid) {
                toast.error(validation.error || 'Invalid file');
                options.callback('error');
                return;
            }

            uploadCourseAsset(options.files[0])
                .then((result) => {
                    options.callback('success', result.cacheUrl);
                })
                .catch((error) => {
                    options.callback('error');
                });
        };

        creator.onUploadFile.add(onUploadFile);
        return () => {
            creator.onUploadFile.remove(onUploadFile);
        };
    }, [account?._id, creator]);

    const unblockRef = useRef<(() => void) | null>(null);

    useEffect(() => {
        const handlePopState = () => {
            if (isSaving) return;
            if (hasUnsavedChanges || creator.state === 'modified') {
                setConfirmationType('unsavedChanges');
            }
        };

        // Set up single history block
        unblockRef.current = history.block(() => {
            if (isSaving) return true;
            if (hasUnsavedChanges || creator.state === 'modified') {
                setConfirmationType('unsavedChanges');
                return false;
            }
            return true;
        });

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
            if (unblockRef.current) {
                unblockRef.current();
            }
        };
    }, [hasUnsavedChanges, creator.state, history, isSaving]);

    const handleExitClick = () => {
        if (hasUnsavedChanges || creator.state === 'modified') {
            setConfirmationType('unsavedChanges');
        } else {
            history.push(`/${account?._id}/courses`);
        }
    };
    const confirmModalConfig = {
        unsavedChanges: {
            title: 'Unsaved Changes',
            message: (
                <Typography variant="base">
                    <span className="font-bold">Are you sure you wish to exit this Course?</span>{' '}
                    Your changes will be not be saved.
                </Typography>
            ),
            confirmText: 'Yes, Cancel Changes',
            cancelText: 'No, Go Back',
            confirmBtnColor: 'red' as const,
            onConfirm: () => {
                if (unblockRef.current) {
                    unblockRef.current();
                }
                setConfirmationType(null);
                history.goBack();
            },
        },
        unpublish: {
            title: 'Unpublish Course',
            message: (
                <Typography variant="base">
                    <span className="font-bold">
                        Are you sure you wish to unpublish this Course?
                    </span>{' '}
                    It will no longer be accessible to your selected audience. You can always
                    republish in the future.
                </Typography>
            ),
            confirmText: 'Unpublish',
            cancelText: 'Back',
            confirmBtnColor: 'blue' as const,
            onConfirm: async () => {
                setConfirmationType(null);
                await handleSaveAsUnpublished(creator);
            },
        },
    };

    // Update handleSaveAsUnpublished in the context to use this confirmation
    const handleUnpublishClick = () => {
        setConfirmationType('unpublish');
    };
    const resourceState: CourseStatus = duplicateFrom
        ? 'draft'
        : existingResource?.status || 'draft';
    const activeConfig = confirmationType
        ? confirmModalConfig[confirmationType]
        : confirmModalConfig.unsavedChanges;

    useEffect(() => {
        const handleKeyPress = async (e: KeyboardEvent) => {
            if ((e.metaKey || e.ctrlKey) && e.key === 's') {
                e.preventDefault();
                if (isReviewing || (!hasUnsavedChanges && creator.state !== 'modified')) return;

                const result = await handleSaveCourse(creator);
                if (result && typeof result === 'object' && 'learningResourceId' in result) {
                    history.push(`/${account?._id}/courses/${result.learningResourceId}/edit`);
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [
        creator,
        resourceState,
        isReviewing,
        hasUnsavedChanges,
        handleSaveAsDraft,
        handleSaveAsPublished,
        history,
        account?._id,
    ]);

    return (
        <>
            <FullscreenNav
                className="*:!relative *:!m-0"
                fixedHeaderProps={{
                    title: (
                        <div className="flex items-center ">
                            <img src={coursesGraphic} alt="person at whiteboard" className="w-12" />

                            <div className="mr-1">Course: {courseName || ''}</div>
                            {duplicateFrom ? (
                                <Badge label="Draft" color="yellow" />
                            ) : (
                                <>
                                    {existingResource?.status === 'unpublished' && (
                                        <Badge label="Unpublished" color="neutral" />
                                    )}
                                    {existingResource?.status === 'published' && (
                                        <Badge label="Published" color="green" />
                                    )}
                                    {existingResource?.status === 'draft' && (
                                        <Badge label="Draft" color="yellow" />
                                    )}
                                </>
                            )}
                        </div>
                    ),
                    leftElements: (
                        <Button variant="outlined" color="white" onClick={handleExitClick}>
                            Exit
                        </Button>
                    ),
                    rightElements: (
                        <div className="flex items-center gap-2">
                            {getCourseActionButtons({
                                state: resourceState,
                                isReviewing,
                                handlers: {
                                    handleSaveAsDraft,
                                    handleUnpublishClick,
                                    handleSaveAsPublished,
                                    handleReviewCourse,
                                },
                                creator,
                            }).map((button) => (
                                <Button
                                    key={button.label}
                                    variant={button.variant}
                                    color={button.color}
                                    onClick={() => button.onClick(creator)}
                                    tooltip={button.tooltip}
                                >
                                    {button.label}
                                </Button>
                            ))}
                        </div>
                    ),
                    className: 'relative m-0',
                }}
                disableAppNavbar
            >
                {isReviewing ? (
                    <AdminCourseReview creator={creator} />
                ) : (
                    <AdminCoursesForm creator={creator} />
                )}
            </FullscreenNav>

            <ConfirmModal
                isVisible={!!confirmationType}
                onClose={() => setConfirmationType(null)}
                onConfirm={activeConfig.onConfirm}
                title={activeConfig.title}
                confirmText={activeConfig.confirmText}
                confirmBtnColor={activeConfig.confirmBtnColor}
                confirmBtnVariant="smooth"
                cancelText={activeConfig.cancelText}
                message={activeConfig.message}
            />

            <CourseAssetsModal
                isVisible={showGalleryModal && !!enableGallery}
                onCloseHandler={() => setShowGalleryModal(false)}
            />
        </>
    );
};

export default () => {
    const { account } = useSparkplugAccount();
    return (
        <LearningResourceProvider sparkplugAccount={account}>
            <AdminCoursesCreate />
        </LearningResourceProvider>
    );
};
