import NotificationsAPI from '@api/NotificationsAPI';
import moment from 'moment';

import { sortByDate } from '@helpers/ui';

const fetchNotifications = (userId: string, archived = false, read = false) => {
    return NotificationsAPI.getAllNotifications(userId, archived, read);
};

export const getNotifications = async (userId: string, archived = false, read = false) => {
    const { data } = await fetchNotifications(userId, archived, read);

    return data || [];
};

export const getNotificationItems = async (userId: string, archived = false, read = false) => {
    const notifications = await getNotifications(userId, archived, read);
    return notifications.filter((obj) => obj != null).sort(sortByDate('createdAt', 'desc'));
};

const updateNotification = async (
    notificationId: string,
    notificationProps: {
        archivedAt?: string;
        readAt?: string;
    },
) => {
    return NotificationsAPI.updateNotification({ notificationId }, notificationProps);
};

export const markNotificationRead = (notificationId: string) => {
    return updateNotification(notificationId, {
        readAt: moment().toISOString(),
    });
};
